<template>
  <div>
    <div class="list-devices">
      <div
        class="device"
        :class="{ active: selectedDevice === 'desktop' }"
        @click="selectDevice('desktop')"
      >
        <i class="uil uil-desktop-alt"></i>
      </div>
      <div
        class="device"
        :class="{ active: selectedDevice === 'mobile' }"
        @click="selectDevice('mobile')"
      >
        <i class="uil uil-mobile-android-alt"></i>
      </div>
    </div>
    <div class="preview-mockup" :class="selectedDevice">
      <div v-if="isShowPopup" 
        :style="{ 
          borderRadius: selectedDevice === 'mobile' ? '24px' : '',
          zoom: popup.sections.styling.MaxWidth > 600 && selectedDevice === 'desktop' ? 600 / popup.sections.styling.MaxWidth : 1,
        }"
        style="display:flex;justify-content:center;">
        <div class="popup-widget" :style="containerStyle">
          <button :style="closeButtonStyle" class="close-btn" @click="isShowPopup = false;">
            <svg id="IconChangeColor" width="200" height="200" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path id="mainIconPathAttribute" d="M14 14L34 34" stroke-width="4" stroke-linecap="round" stroke-linejoin="round" :stroke="popup.sections.closeButton.Color"></path>
              <path id="mainIconPathAttribute" d="M14 34L34 14" stroke-width="4" stroke-linecap="round" stroke-linejoin="round" :stroke="popup.sections.closeButton.Color"></path>
            </svg>
          </button>
          <template v-if="step === 1">
            <div v-if="popup.sections.styling.Align === 'left' || selectedDevice === 'mobile'"  :style="rightEmailContentStyle" class="widget-template" :class="popup.sections.styling.Template">
              <WidgetTheme 
                v-if="popup.sections.styling.Template && selectedDevice === 'desktop'" :theme="popup.sections.styling.Template"
                :color="popup.sections.styling.BackgroundColor"
                :theme-color="popup.sections.styling.TemplateColor"
                :line-width="popup.sections.styling.LineWidth"
                :align="popup.sections.styling.Align"
              ></WidgetTheme>
            </div>
            <div :style="leftEmailContentStyle">
              <div class="widget-block">
                <h1 :style="titleEmailStyle">{{ popup.sections.emailStep.Content.Title }}</h1>
                <p :style="descEmailStyle">
                  {{ popup.sections.emailStep.Content.Description }}
                </p>
              </div>
              <div class="widget-block">
                <input
                  type="email"
                  :placeholder="popup.sections.emailStep.Input.Placeholder"
                  :style="inputEmailStyle"
                  readonly
                />
              </div>
              <div class="widget-block">
                <div class="test">
                  <button :style="actionEmailStyle" @click="nextStep">
                    {{ popup.sections.emailStep.Button.Label }}
                  </button>
                </div>
              </div>
            </div>
            <div v-if="popup.sections.styling.Align === 'right' && selectedDevice !== 'mobile'"  :style="rightEmailContentStyle" class="widget-template" :class="`${popup.sections.styling.Template} right`">
              <WidgetTheme 
                v-if="popup.sections.styling.Template && selectedDevice === 'desktop'" :theme="popup.sections.styling.Template"
                :color="popup.sections.styling.BackgroundColor"
                :theme-color="popup.sections.styling.TemplateColor"
                :line-width="popup.sections.styling.LineWidth"
                :align="popup.sections.styling.Align"
              ></WidgetTheme>
            </div>
          </template>
          <template v-if="step === 2">
            <div v-if="popup.sections.styling.Align === 'left' || selectedDevice === 'mobile'" :style="rightPhoneContentStyle" class="widget-template" :class="popup.sections.styling.Template">
              <WidgetTheme 
                v-if="popup.sections.styling.Template && selectedDevice === 'desktop'" :theme="popup.sections.styling.Template"
                :color="popup.sections.styling.BackgroundColor"
                :theme-color="popup.sections.styling.TemplateColor"
                :line-width="popup.sections.styling.LineWidth"
                :align="popup.sections.styling.Align"
              ></WidgetTheme>
            </div>
            <div :style="leftPhoneContentStyle">
              <div class="widget-block">
                <h1 :style="titlePhoneStyle">{{ popup.sections.phoneStep.Content.Title }}</h1>
                <p :style="descPhoneStyle">
                  {{ popup.sections.phoneStep.Content.Description }}
                </p>
              </div>
              <div class="widget-block">
                <input
                  type="text"
                  :placeholder="popup.sections.phoneStep.Input.Placeholder"
                  :style="inputPhoneStyle"
                  readonly
                />
              </div>
              <div class="widget-block">
                <button :style="actionPhoneStyle" @click="nextStep">
                  {{ popup.sections.phoneStep.Button.Label }}
                </button>
              </div>
              <div class="widget-block" style="margin-bottom: 0;">
                <p :style="legalPhoneStyle">
                  {{ popup.sections.phoneStep.Content.Legal | legal }}
                </p>
              </div>
              <div class="widget-block">
                <p v-if="popup.sections.phoneStep.NoThanks.Label" :style="noThanksStyle" @click="nextStep">
                  {{ popup.sections.phoneStep.NoThanks.Label }}
                </p>
              </div>
            </div>
            <div v-if="popup.sections.styling.Align === 'right' && selectedDevice !== 'mobile'" :style="rightPhoneContentStyle" class="widget-template" :class="`${popup.sections.styling.Template} right`">
              <WidgetTheme 
                v-if="popup.sections.styling.Template && selectedDevice === 'desktop'" :theme="popup.sections.styling.Template"
                :color="popup.sections.styling.BackgroundColor"
                :theme-color="popup.sections.styling.TemplateColor"
                :line-width="popup.sections.styling.LineWidth"
                :align="popup.sections.styling.Align"
              ></WidgetTheme>
            </div>
          </template>
          <template v-if="step === 3">
            <div  v-if="popup.sections.styling.Align === 'left' || selectedDevice === 'mobile'" :style="rightThanksStyle" class="widget-template" :class="popup.sections.styling.Template">
              <WidgetTheme 
                v-if="popup.sections.styling.Template && selectedDevice === 'desktop'" :theme="popup.sections.styling.Template"
                :color="popup.sections.styling.BackgroundColor"
                :theme-color="popup.sections.styling.TemplateColor"
                :line-width="popup.sections.styling.LineWidth"
                :align="popup.sections.styling.Align"
              ></WidgetTheme>
            </div>
            <div :style="leftThanksStyle">
              <div :style="thanksStepStyle">
                {{  popup.sections.thanksStep.Content  }}
              </div>
              <p :style="descThanksStyle">
                {{ popup.sections.thanksStep.Desc }}
              </p>
            </div>
            <div  v-if="popup.sections.styling.Align === 'right' && selectedDevice !== 'mobile'" :style="rightThanksStyle" class="widget-template" :class="`${popup.sections.styling.Template} right`">
              <WidgetTheme 
                v-if="popup.sections.styling.Template && selectedDevice === 'desktop'" :theme="popup.sections.styling.Template"
                :color="popup.sections.styling.BackgroundColor"
                :theme-color="popup.sections.styling.TemplateColor"
                :line-width="popup.sections.styling.LineWidth"
                :align="popup.sections.styling.Align"
              ></WidgetTheme>
            </div>
          </template>
        </div>
      </div>
      <div v-if="popup.sections.triggerButton.IsEnable" style="text-align: center;margin-top: 30px;">
        <button :style="triggerButtonStyle" @click="handleTrigger">
          <i
            v-if="popup.sections.triggerButton.Type === 'icon-left'"
            class="icon"
            :class="popup.sections.triggerButton.Icon"
          ></i>
          {{ popup.sections.triggerButton.Label }}
          <i
            v-if="popup.sections.triggerButton.Type === 'icon-right'"
            class="icon"
            :class="popup.sections.triggerButton.Icon"
          ></i>
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import WidgetTheme from './WidgetTheme.vue'

export default {
  components: {
    WidgetTheme
  },

  props: {
    popup: {
      type: [Object],
      default: null,
    },

    device: {
      type: String,
      default: 'desktop',
    },
  },
  
  data() {
    return {
      selectedDevice: 'desktop',
      isShowPopup: true,
      step: 1,
    }
  },

  computed: {
    user() {
      return this.$store.getters['auth/user']
    },

    triggerButtonStyle() {
      let styles = {
        background: this.popup.sections.triggerButton.BackgroundColor,
        border: `1px solid ${this.popup.sections.triggerButton.BorderColor}`,
        color: this.popup.sections.triggerButton.Color,
        fontSize: `${this.popup.sections.triggerButton.FontSize}px`,
        borderRadius: `${this.popup.sections.triggerButton.Roundness}px`,
        padding: `${this.popup.sections.triggerButton.Padding}px`,
        position: 'absolute',
        bottom: '15px',
      }

      switch (this.popup.sections.triggerButton.Position) {
        case 'left':
          styles = {
            ...styles,
            left: '15px'
          }
          break;
        case 'center':
          styles = {
            ...styles,
            left: `calc(100% - 80px)`
          }
          break;
        case 'right':
          styles = {
            ...styles,
            right: '15px'
          }
          break;
      }

      return styles
    },

    closeButtonStyle() {
      return {
        background: this.popup.sections.closeButton.BackgroundColor,
        border: `1px solid ${this.popup.sections.closeButton.BorderColor}`,
        fontSize: `${this.popup.sections.closeButton.Size}px`,
        width: `${Number(this.popup.sections.closeButton.Size) + 10}px`,
        height: `${Number(this.popup.sections.closeButton.Size) + 10}px`,
        lineHeight: `${this.popup.sections.closeButton.Size}px`,
        borderRadius: '50%',
        zIndex: 10,
        position: 'absolute',
        top: `${this.popup.sections.closeButton.Top}px`,
        right: `${this.popup.sections.closeButton.Right}px`,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        fontFamily: "Arial, Helvetica, sans-serif",
      }
    },

    descEmailStyle() {
      return {
        fontSize: `${this.popup.sections.emailStep.Content.DescriptionFontSize}px`,
        color: this.popup.sections.emailStep.Content.DescriptionColor,
        textAlign: this.popup.sections.emailStep.Content.DescriptionAlign,
        padding: '0px',
        margin: '0px',
      }
    },

    leftEmailContentStyle() {
      let styles = {
        flex: '1 1 50%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        padding: this.selectedDevice === 'mobile' ? '15px' : `${this.popup.sections.emailStep.Content.PaddingY}px ${this.popup.sections.emailStep.Content.PaddingX}px`,
        flexDirection: 'column'
      }

      if (this.popup.sections.styling.Align === 'center') {
        styles.backgroundImage = `url(${this.popup.sections.emailStep.Content.BackgroundImg})`
        styles.backgroundSize = 'cover'
        styles.backgroundPosition = 'center'
        styles.boxShadow = `inset 0 0 0 2000px ${this.popup.sections.styling.BackgroundColor}`
      }

      return styles
    },

    titleEmailStyle() {
      return {
        fontSize: `${this.popup.sections.emailStep.Content.FontSize}px`,
        color: this.popup.sections.emailStep.Content.Color,
        textAlign: this.popup.sections.emailStep.Content.Align,
      }
    },

    inputEmailStyle() {
      return {
        backgroundColor: this.popup.sections.emailStep.Input.BackgroundColor,
        borderWidth: '1px',
        borderStyle: 'solid',
        borderColor:
          this.popup.sections.emailStep.Input.BorderColor || 'transparent',
        color: this.popup.sections.emailStep.Input.Color,
        display: 'inline-block',
        borderRadius: `${this.popup.sections.emailStep.Input.Roudness}px`,
        fontSize: `${this.popup.sections.emailStep.Input.FontSize}px`,
        lineHeight: `28px`,
        padding: `10px 16px`,
        marginTop: `${this.popup.sections.emailStep.Input.MarginTop}px`,
        width: '100%',
      }
    },

    actionEmailStyle() {
      return {
        width: '100%',
        marginTop: `${this.popup.sections.emailStep.Button.MarginTop}px`,
        background: this.popup.sections.emailStep.Button.BackgroundColor,
        border: `1px solid ${this.popup.sections.emailStep.Button.BorderColor}`,
        color: this.popup.sections.emailStep.Button.Color,
        padding: `${this.popup.sections.emailStep.Button.Padding}px 0px`,
        fontSize: `${this.popup.sections.emailStep.Button.FontSize}px`,
        borderRadius: `${this.popup.sections.emailStep.Button.Roudness}px`,
      }
    },

    rightEmailContentStyle() {
      let styles = {
        backgroundImage: `url(${this.popup.sections.emailStep.Content.BackgroundImg})`,
        flex: '1 1 50%',
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        position: 'relative',
        overflow: 'hidden',
      }

      if (this.popup.sections.styling.Template === 'default')
      {
        if (this.selectedDevice !== 'mobile')
          styles['border'] = `${this.popup.sections.styling.LineWidth}px solid ${this.popup.sections.styling.TemplateColor}`
      }

      if (this.selectedDevice === 'mobile')
        if (this.popup.sections.styling.Align !== 'center')
        {
          styles = {
            ...styles,
            flex: `0 0 25%`,
            borderBottom: `${this.popup.sections.styling.LineWidth}px solid ${this.popup.sections.styling.TemplateColor}`,
          }
        } else {
          styles['display'] = 'none'
        }
      
      return styles
    },

    descPhoneStyle() {
      return {
        fontSize: `${this.popup.sections.phoneStep.Content.DescriptionFontSize}px`,
        color: this.popup.sections.phoneStep.Content.DescriptionColor,
        textAlign: this.popup.sections.phoneStep.Content.DescriptionAlign,
        padding: '0px',
        margin: '0px',
        width: '100%',
      }
    },

    legalPhoneStyle() {
      return {
        fontSize: `${this.popup.sections.phoneStep.Content.LegalFontSize}px`,
        color: this.popup.sections.phoneStep.Content.LegalColor,
        textAlign: this.popup.sections.phoneStep.Content.LegalAlign,
        margin: '0px',
        padding: '0px',
        marginTop: `${this.popup.sections.phoneStep.Content.LegalMarginTop}px`,
      }
    },

    leftPhoneContentStyle() {
      let styles = {
        flex: '1 1 50%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        padding: `${this.popup.sections.phoneStep.Content.PaddingY}px ${this.popup.sections.phoneStep.Content.PaddingX}px`,
        flexDirection: 'column'
      }

      if (this.popup.sections.styling.Align === 'center') {
        styles.backgroundImage = `url(${this.popup.sections.phoneStep.Content.BackgroundImg})`
        styles.backgroundSize = 'cover'
        styles.backgroundPosition = 'center'
        styles.boxShadow = `inset 0 0 0 2000px ${this.popup.sections.styling.BackgroundColor}`
      }

      return styles
    },

    titlePhoneStyle() {
      return {
        fontSize: `${this.popup.sections.phoneStep.Content.FontSize}px`,
        color: this.popup.sections.phoneStep.Content.Color,
        textAlign: this.popup.sections.phoneStep.Content.Align,
      }
    },

    inputPhoneStyle() {
      return {
        backgroundColor: this.popup.sections.phoneStep.Input.BackgroundColor,
        borderWidth: '1px',
        borderStyle: 'solid',
        borderColor:
          this.popup.sections.phoneStep.Input.BorderColor || 'transparent',
        color: this.popup.sections.phoneStep.Input.Color,
        display: 'inline-block',
        borderRadius: `${this.popup.sections.phoneStep.Input.Roudness}px`,
        fontSize: `${this.popup.sections.phoneStep.Input.FontSize}px`,
        lineHeight: `28px`,
        padding: `10px 16px`,
        marginTop: `${this.popup.sections.phoneStep.Input.MarginTop}px`,
        width: '100%',
      }
    },

    actionPhoneStyle() {
      return {
        width: '100%',
        marginTop: `${this.popup.sections.phoneStep.Button.MarginTop}px`,
        background: this.popup.sections.phoneStep.Button.BackgroundColor,
        border: `1px solid ${this.popup.sections.phoneStep.Button.BorderColor}`,
        color: this.popup.sections.phoneStep.Button.Color,
        padding: `${this.popup.sections.phoneStep.Button.Padding}px 0px`,
        fontSize: `${this.popup.sections.phoneStep.Button.FontSize}px`,
        borderRadius: `${this.popup.sections.phoneStep.Button.Roudness}px`,
      }
    },

    rightPhoneContentStyle() {
      let styles = {
        backgroundImage: `url(${this.popup.sections.phoneStep.Content.BackgroundImg})`,
        flex: '1 1 50%',
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        position: 'relative',
        overflow: 'hidden',
      }

      if (this.popup.sections.styling.Template === 'default')
      {
        if (this.selectedDevice !== 'mobile')
          styles['border'] = `${this.popup.sections.styling.LineWidth}px solid ${this.popup.sections.styling.TemplateColor}`
      }

      if (this.selectedDevice === 'mobile')
        if (this.popup.sections.styling.Align !== 'center')
        {
          styles = {
            ...styles,
            flex: `0 0 25%`,
            borderBottom: `${this.popup.sections.styling.LineWidth}px solid ${this.popup.sections.styling.TemplateColor}`,
          }
        } else {
          styles['display'] = 'none'
        }
      
      return styles
    },
    
    containerStyle() {
      let styles = {
        background: this.popup.sections.styling.BackgroundColor,
        width: `${this.popup.sections.styling.MaxWidth}px`,
        minHeight: `${this.popup.sections.styling.MinHeight}px`,
        borderRadius: `${this.popup.sections.styling.Roudness}px`,
        display: 'flex',
        flexDirection: this.selectedDevice === 'mobile' ? 'column' : 'row',
        position: 'relative',
        boxShadow: 'rgba(0, 0, 0, 0.15) 0px 0px 30px',
        overflow: 'hidden',
      }
      if (this.selectedDevice !== 'mobile')
      {
        styles['border'] = `${this.popup.sections.styling.BorderWidth}px solid ${this.popup.sections.styling.BorderColor}`
      }

      return styles
    },

    leftThanksStyle() {
      let styles = {
        flex: '1 1 50%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'column',
        padding: `${this.popup.sections.thanksStep.PaddingY}px ${this.popup.sections.thanksStep.PaddingX}px`,
      }

      if (this.popup.sections.styling.Align === 'center') {
        styles.backgroundImage = `url(${this.popup.sections.thanksStep.BackgroundImg})`
        styles.backgroundSize = 'cover'
        styles.backgroundPosition = 'center'
        styles.boxShadow = `inset 0 0 0 2000px ${this.popup.sections.styling.BackgroundColor}`
      }

      return styles
    },

    rightThanksStyle() {
      let styles =  {
        backgroundImage: `url(${this.popup.sections.thanksStep.BackgroundImg})`,
        flex: '1 1 50%',
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        position: 'relative',
        overflow: 'hidden',
      }

      if (this.popup.sections.styling.Template === 'default')
      {
        if (this.selectedDevice !== 'mobile')
          styles['border'] = `${this.popup.sections.styling.LineWidth}px solid ${this.popup.sections.styling.TemplateColor}`
      }

      if (this.selectedDevice === 'mobile')
        if (this.popup.sections.styling.Align !== 'center')
        {
          styles = {
            ...styles,
            flex: `0 0 25%`,
            borderBottom: `${this.popup.sections.styling.LineWidth}px solid ${this.popup.sections.styling.TemplateColor}`,
          }
        } else {
          styles['display'] = 'none'
        }

      return styles
    },

    thanksStepStyle() {
      let styles = {
        background: this.popup.sections.thanksStep.BackgroundColor,
        color: this.popup.sections.thanksStep.Color,
        textAlign: this.popup.sections.thanksStep.Align,
        fontSize: `${this.popup.sections.thanksStep.FontSize}px`,
        width: '100%'
      }

      if (this.selectedDevice === 'mobile') {
        styles = {
          ...styles,
          display: 'flex',
          justifyContent: 'center',
          flexDirection: 'column',
          padding: 'initial',
        }
      }

      return styles
    },

    descThanksStyle() {
      return {
        color: this.popup.sections.thanksStep.DescColor,
        textAlign: this.popup.sections.thanksStep.DescAlign,
        fontSize: `${this.popup.sections.thanksStep.DescFontSize}px`,
        marginTop: `${this.popup.sections.thanksStep.DescMarginTop}px`,
      }
    },

    noThanksStyle() {
      return {
        cursor: 'pointer',
        padding: '0px',
        margin: '0px',
        marginTop: `${this.popup.sections.phoneStep.NoThanks.MarginTop}px`,
        color: this.popup.sections.phoneStep.NoThanks.Color,
        fontSize: `${this.popup.sections.phoneStep.NoThanks.FontSize}px`,
        textAlign: 'center',
      }
    }
  },

  watch: {
    selectedDevice(val) {
      this.isShowPopup = true
      if (this.popup.sections.triggerButton.Timeout > 0) {
        setTimeout(() => {
          this.isShowPopup = true
        }, this.popup.sections.triggerButton.Timeout * 1000)
      }
    }
  },

  mounted() {
    if (this.popup.sections.triggerButton.Timeout > 0) {
      setTimeout(() => {
        this.isShowPopup = true
      }, this.popup.sections.triggerButton.Timeout * 1000)
    }
  },

  methods: {
    selectDevice(device) {
      this.selectedDevice = device
    },

    nextStep() {
      if (this.popup.sections.phoneStep.IsEnable) {
        this.step += 1
      } else {
        this.step = 3
      }
    },

    handleTrigger() {
      this.isShowPopup = !this.isShowPopup
      this.step = 1
    }
  },
}
</script>

<style lang="scss" scoped>
.preview-mockup {
  padding: 30px;
  min-height: 700px !important;
  &.mobile {
    padding: 0px;

    .popup-widget {
      position: absolute !important;
      border-radius: 24px !important;
      width: 100% !important;
      height: 100% !important;
      overflow: hidden;
      z-index: 20;
    }
  }

  &:not(.mobile) {
    .widget-template {
      div {
        position: absolute;
        width: 50%;
        right: -20%;
        top: -10%;
        height: 120%;
        svg {
          height: 104%;
          width: 103%;
        }
      }

      &.right {
        div {
          right: initial;
          left: -20%;
        }
      }
      &.default {
        border-top: none!important;
        border-bottom: none!important;
        &.right {
          border-right: none!important;
        }
        &:not(.right) {
          border-left: none!important;
        } 
      }
    }
  }
}
.popup-widget {
  background-color: transparent;

  
  .powered-by-content {
    color: #666;
    padding-right: 10px;
    padding-top: 5px;
    display: block;
    font-size: 14px;
    a {
      color: #666;
      font-weight: bold;
      &:hover {
        text-decoration: underline!important;
      };
    }
  }
  .widget-block {
    border: none;
    margin-bottom: 15px;
    position: relative;
    width: 100%;
  }
  .widget-toggle {
    margin-top: 25px;
    text-align: right;
    cursor: pointer;
  }
  .widget-default {
    text-align: center;
    img {
      width: 40px;
      margin: 20px;
    }
    .widget-optional {
      opacity: 0.5;
    }
    .widget-caption {
      color: rgba(0, 27, 72, 0.54);
      font-size: 16px;
    }
  }
  .widget-caption {
    margin-bottom: 20px;
  }
  .widget-copyright {
    text-align: center;
    margin-top: 16px;
    a {
      font-size: 13px;
      color: rgba(0, 27, 72, 0.54);
    }
  }
  .phone-input {
    margin-bottom: 15px;
    position: relative;
    color: #414141;
    .phone-code {
      position: absolute;
      top: 10px;
      padding: 0px 8px;
      img {
        width: 24px;
        margin-right: 5px;
      }
      .code {
        font-weight: 600;
        font-size: 12px;
      }
    }
    input {
      border: 1px solid #ced4da;
      padding: 10px 12px;
      padding-left: 60px;
      font-weight: 600;
      display: block;
      border-radius: 5px;
      width: 100%;
    }
  }
}
</style>
